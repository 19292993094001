import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const Footer = ({ location, dir, wl }) => {
  const { t } = useTranslation();
  // const rtl = dir === 'rtl';

  return (
    <div className="footer mt-5">
      <div className="footer-content">
        {wl ? t("poweredBy") + " " : ""}{t('footer')}<br/>
        <a href="/">{t('nav-search-engine')}</a><br/>
        <a href="https://paapmpaapm.com/">{t('nav-price-cut')}</a><br/>
        <a href="https://paapmpaapm.com/covers-explained/">{t('coversExplained')}</a><br/>
        <a href="https://paapmpaapm.com/suppliers/">{t('rentalCompaniesGuide')}</a><br/>
        <a href="https://paapmpaapm.com/cars-guide/groups/">{t('carsGuide')}</a><br/>
      </div>
    </div>
  );
}

const mapStateToProps = ({ vendors, results, ui }) => ({
  dir: ui.direction,
  wl: ui.whiteLabel,
});

export default connect(mapStateToProps)(Footer);