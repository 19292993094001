import { handleActions } from 'redux-actions';
import {
  setLoaded,
  setLoading,
  setDirection,
  setLanguage,
  setCurrency,
  setWhiteLabel,
} from './action';
import { currencies } from "../../constants/default"

const DEFAULT_STATE = {
  loading: true,
  loaded: false,
  whiteLabel: false,
  // direction: RTL_DIRECTION[i18n.language.substring(0,2)] ? 'rtl' : 'ltr',
  // language: i18n.language.substring(0,2),
  currency: "USD",
};

const handlers = {
  [setLoading]: (state, action) => ({ ...state, loading: !!action.payload }),
  [setLoaded]: (state, action) => ({ ...state, loaded: action.payload }),
  [setDirection]: (state, action) => ({ ...state, direction: action.payload }),
  [setLanguage]: (state, action) => ({ ...state, language: action.payload }),
  [setCurrency]: (state, action) => {
    // in case we got more than 1 cur param in the url take the latest
    action.payload = Array.isArray(action.payload) ? action.payload[action.payload.length-1] : action.payload
    action.payload = action.payload.toLocaleUpperCase()
    if (!currencies.includes(action.payload)) {
      console.log(`currency ${action.payload} is not supported. Fallback to USD`)
      action.payload = "USD" // fallback currency in case someone abuse the currency value to an unsupported one.
    }

    return (
      {
        ...state, currency: action.payload
      });
  },
  [setWhiteLabel]: (state, action) => ({ ...state, whiteLabel: action.payload }),
};

export default handleActions(handlers, DEFAULT_STATE);
