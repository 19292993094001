import React, { useEffect } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./styles.scss";
import { setCurrency } from "../../redux/ui/action";
import { currencies } from "../../constants/default"
import {useHistory} from "react-router";

const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()

const CurrencySwitch = ({ dispatch, cur }) => {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const history = useHistory();
  // console.log('rendering currency switcher.js')

  const changeCurrency = (event) => {
    // console.log('setting currency from switcher.js')
    dispatch(setCurrency(event.target.value));

    // persist cur in url
    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set("cur", event.target.value); // Update or add the query parameter

    history.push({
      pathname: history.location.pathname, // Keep the current path
      search: currentSearchParams.toString(), // Set the updated query string
    });
  };

  return (
    <ButtonGroup toggle>
      {currencies.map((currency) => (
        <ToggleButton
          key={currency}
          type="radio"
          name="currency"
          value={currency}
          checked={cur === currency}
          onChange={changeCurrency}
          variant="outline-dark"
        >
          <span className="d-md-none">{getCurrencySymbol(navigator.languages[0] ,currency)}</span>
          <span className="d-none d-md-inline">{currency}</span>
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

const mapStateToProps = ({ vendors, results, ui, currencies }) => ({
  cur: ui.currency,
});

export default connect(mapStateToProps)(CurrencySwitch);
