import React from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../LanguageSwitch';
import CurrencySwitch from '../CurrencySwitch';

function LocaleModal({dispatch, dir, shouldShow, setModalVisible, cid}) {
  const { t } = useTranslation();
  // console.log(`LocaleModal: ${shouldShow}`)
  // const [show, setShow] = useState(shouldShow);

  const handleClose = () => setModalVisible(false);
  // const handleShow = () => setModalVisible(true);

  return (
    <Modal
      show={shouldShow}
      onHide={handleClose}
      // backdrop="static"
      keyboard={true}
      dir={dir}
      centered
      style={dir === "rtl" ? { textAlign: "right" } : {}}
    >
    <Modal.Header>
        <Modal.Title>{t("localePrefsTitle")}</Modal.Title>
    </Modal.Header>
      <Modal.Body>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-translate" viewBox="0 0 16 16">
          <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
          <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
        </svg>&nbsp;
        {t("selectLanguage")}:<br /><br />
        <LanguageSwitch />
        <br /><br />
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-currency-exchange" viewBox="0 0 16 16">
            <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z"></path>
        </svg>&nbsp;
        {t("selectCurrency")}:<br /><br />
        <CurrencySwitch />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleClose}>{t("close")}</Button>
    </Modal.Footer>
  </Modal>  )
}

const mapStateToProps = ({ ui }) => ({
  dir: ui.direction,
});

export default connect(mapStateToProps)(LocaleModal);