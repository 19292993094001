import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import * as queryString from "query-string";
import {useHistory, useLocation} from "react-router";
import routes from '../constants/routes.json';
import { DEFAULT_FILTERS } from '../constants/default';
import { setVendorsLoaded } from '../redux/vendors/action';
import { setResultsLoaded } from '../redux/results/action';
import HomeContent from '../components/HomeContent';
import { useTranslation } from 'react-i18next';
import RedirectModal from '../components/RedirectModal';
import { getCookie } from '../utils/cookies';

function Home({ dispatch, cur, cid }) {
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState();
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const lng = i18n.language.substring(0, 2)
  const [showRedirectModal, setShowRedirectModal] = useState(false)
  const attributor = window.sessionStorage.getItem("attributor") ? window.sessionStorage.getItem("attributor") : getCookie('attributor')
  const [link, setLink] = useState(`https://discovercars.com/${lng}?utm_medium=aggregator&utm_source=paapmpaapm&utm_campaign=en-IL&affpartner=paapmpaapm&a_aid=paapmpaapm&data1=${cid}&data2=${attributor}`);
  // console.log(`Home cid render: ${cid}`)

  useEffect(() => {
    setLink(`https://discovercars.com/${lng}?utm_medium=aggregator&utm_source=paapmpaapm&utm_campaign=en-IL&affpartner=paapmpaapm&a_aid=paapmpaapm&data1=${cid}&data2=${attributor}`)
    // console.log(`Home cid useEffect: ${cid} ${attributor}`)
  }, [cid, lng, attributor])
  
  useEffect(() => {
    const params = queryString.parse(location.search);

    // qnd fix external links with wrong case
    if (params.fromLocationCode === undefined && params.fromlocationcode) {
      params.fromLocationCode = params.fromlocationcode
    }
    if (params.toLocationCode === undefined && params.tolocationcode) {
      params.toLocationCode = params.tolocationcode
    }
    // console.log(params)
  
    const { endDate, startDate, fromLocationCode, toLocationCode, age, partner, ...rest } = params;
    setParams({ endDate, startDate, fromLocationCode, toLocationCode, age, partner });
  }, []); // eslint-disable-line

  const onSubmit = fields => {
    const params = {
      startDate: `${format(fields.startDate, 'dd/MM/yyyy')}T${fields.hoursFrom}:${fields.minsFrom}`,
      endDate: `${format(fields.endDate, 'dd/MM/yyyy')}T${fields.hoursTo}:${fields.minsTo}`,
      fromLocationCode: fields.fromLocation.locationCode,
      toLocationCode: fields.toLocation.locationCode,
      age: fields.age,
      cur: cur,
      lng: lng,
      ...DEFAULT_FILTERS,
    };

    // testing search in new window (same impl as in widget)
    const url = 'https://search.paapmpaapm.com/' + routes.SEARCH + '?' + queryString.stringify(params);
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }

    // start countdown redirect to partner
    setShowRedirectModal(true)

    // removed original search impl below - keep this line as a reference
  };

  return (
    <div className="home flex-fill bg-white">
      <HomeContent onSubmit={onSubmit} params={params} minimized={false} />
      {showRedirectModal ? <RedirectModal link={link} seconds={5} setModalVisible={(val) => setShowRedirectModal(val)} cid={cid} /> : ""}
    </div>
  );
}

const mapStateToProps = ({ vendors, results, ui }) => ({
  // loading: ui.loading,
  // dir: ui.direction,
  cur: ui.currency,
});

export default connect(mapStateToProps)(Home);
