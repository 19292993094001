import React from 'react';
import {
  isMobile
} from "react-device-detect";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import {map, size} from 'lodash';
import size from 'lodash/size';
import map from 'lodash/map';
import './styles.scss';
import MobileItem from "./MobileItem";
import DesktopItem from "./DesktopItem";
import PromoModal from '../PromoModal';
import ContactUsModal from '../ContactUsModal'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";

import Tooltips from '../Tooltip';
import { Alert } from 'react-bootstrap';

const SearchResults = ({ dir, data = [], onClear, cid, isAirport, locationSlug }) => {
  const [showPromo, setShowPromo] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showContactUs, setShowContactUs] = useState(false);
  

  const setModalVisible = (visible => {
    // console.log(`setShow: ${visible}`)
    setShowPromo(visible)
  })

  const itemClicked = () => {
    setModalVisible(true)
  }

  const [scroll, setScroll] = useState(10);

  const { t } = useTranslation();
//  const locale = LOCALES[i18n.language.substring(0,2)];
  // const locale = i18n.language;

  const trackOffersCount = (count) => {
    if (window.gtag) {
      window.gtag('event', 'Offers found', {
        'event_category': count === 0 ? 'Requirements' : 'Rating',
        // 'event_label': count,
        'value': count,
      })
    }
    console.log(count);
  }

  useEffect(() => {
    trackOffersCount(data.length);
  }, []);

  return (
    <div className={"results-section " + (dir == "rtl" ? "pr-md-3" : "pl-md-3")}>
      {size(data) > 0 ? (
        <>
          <Alert show={showIntro} variant="info">
            <Alert.Heading>{t("introHeader")}</Alert.Heading>
            <p>
              {t("intro")}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowIntro(false)} variant="outline-info">
                {t("gotIt")}
              </Button>&nbsp;
              <Button onClick={() => setShowContactUs(true)} variant="outline-dark">
                {t("waitAminute")}
              </Button>
            </div>
          </Alert>

          <InfiniteScroll style={{ overflow: "visible" }}
          dataLength={scroll}
          next={() => { setScroll(scroll < data.length ? scroll + Math.min(10, data.length - scroll) : scroll) } }
          hasMore={scroll < data.length}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>---</b>
            </p>
          }
          >
            {map(data.slice(0,scroll), (item, key) =>
              isMobile ? (
                <MobileItem key={key} item={item} dir={dir} onItemClicked={itemClicked} cid={cid} isAirport={isAirport} locationSlug={locationSlug} />
              ) : (
                  <DesktopItem key={key} item={item} dir={dir} onItemClicked={itemClicked} cid={cid} isAirport={isAirport} locationSlug={locationSlug} />
              )
            )}
          </InfiniteScroll>
        </>
      ) : (
          <Row className="item-wrapper">
            <Col>
              <div>{t("tooManyFilters")}</div>
              <div>
              <Button variant="primary" className="mt-3" onClick={onClear}>
                {t("clearFilters")}
                </Button>
                </div>
            </Col>
          </Row>
      )}
      <PromoModal dir={dir} shouldShow={showPromo} setModalVisible={setModalVisible} cid={cid}></PromoModal>
      <ContactUsModal dir={dir} shouldShow={showContactUs} setModalVisible={setShowContactUs} ></ContactUsModal>
      <Tooltips />
    </div>
  );
};

export default SearchResults;
