import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as queryString from "query-string";
import {useHistory} from "react-router";
import LocaleModal from '../LocaleModal';
import { getCurrencies } from "../../redux/currencies/action";

const Header = ({ dispatch, dir, wl, /*lng*/ }) => {
  const [showLocaleModal, setShowLocaleModal] = useState(false);

  // console.log('render header')
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const rtl = dir === 'rtl';
  const lng = i18n.language.substring(0, 2)

  useEffect(() => {
    // console.log("Fetching currencies once only...");
    dispatch(getCurrencies({ force: "dummyforce" }));
  }, []);

  return (
    <>
    <Navbar className="header" dir={rtl ? 'rtl' : 'ltr'} expand="false">
      <MyHelmet lng={lng}></MyHelmet>
        <div role="group" aria-label="Preferences" >
        <button type="button" className="btn btn-lg">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ fill: "#87d6e4" }} className="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
          </svg>
        </button>
        <button onClick={() => setShowLocaleModal(true)} type="button" className="btn btn-lg">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ fill: "#87d6e4" }} className="bi bi-currency-exchange" viewBox="0 0 16 16">
            <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z"></path>
          </svg>
        </button>
      </div>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
      {/* <span className="d-lg-none">
        {wl ? (<span className='text-muted'>{t("poweredBy")} </span>): (<span></span>)}
        {t("brand")}
      </span> */}
      <span>
        {/* <span className="d-none d-lg-inline">
          {wl ? (<span className='text-muted'>{t("poweredBy")} </span>): (<span></span>)}
          {t("brand")}
        </span>&nbsp;&nbsp; */}
        <a href="/"><img src="/logo.png" className='rounded' style={{ height: "58px" }} alt="paapmpaapm logo"></img></a>
      </span>
      {/* <Navbar.Collapse id="basic-navbar-nav">
        <span className="nav-link"><LanguageSwitch /></span>
        <span className="nav-link"><CurrencySwitch /></span>
    </Navbar.Collapse> */}
    </Navbar>
    <LocaleModal dir={dir} shouldShow={showLocaleModal} setModalVisible={setShowLocaleModal} ></LocaleModal>
  </>
  )
}

function MyHelmet(props) {
  const history = useHistory();

  // adding lang part of the url if not already set
  const strippedPath = window.location.pathname.replace(/^\/(en|he|ar)/i, "")
  let location = window.location.origin + `/${props.lng}` + strippedPath;

  // const queryParams = queryString.parse(window.location.search);
  // remove any affiliate and wl params for canonical
  // delete queryParams["wl"] <-- we actually remove them below anyway with the whitelist approach below
  // delete queryParams["partner"]
  // delete queryParams["utm_campaign"]
  // delete queryParams["utm_source"]
  // delete queryParams["utm_medium"]
  // delete queryParams["lng"]

  const canonicalAllowedQueryParams = ["fromLocationCode", "toLocationCode"]
  const canonicalParams = queryString.pick(window.location.search, canonicalAllowedQueryParams)
  // console.log("canonicalParams", canonicalParams)

  const canonical = `${location}${canonicalParams}`

  const alternates = []
  Array("en", "he", "ar").forEach(language => {
    alternates.push(
      <link rel="alternate" href={`${window.location.origin}/${language}${strippedPath}${canonicalParams}`} hreflang={language} key={language} />
    )
  });

  const { t } = useTranslation();
  const params = {};
    params[
      "title"
    ] = t('headTitle')
    params[
      "og:title"
    ] = t('headTitle')
    params[
      "description"
    ] = t('headDescription')
    params[
      "og:description"
    ] = t('headDescription')

  useEffect(() => {
    if (location !== `${window.location.origin}${window.location.pathname}`) {
      // console.log(`updating location from: ${window.location} to: /${props.lng}${strippedPath}${window.location.search}`)
      history.replace(`/${props.lng}${strippedPath}${window.location.search}`)
      // history.push(`${location}${window.location.search}`);
      // history.push(routes.SEARCH);
  
      // window.history.pushState({}, '', `${location}${window.location.search}`);
    }
  }, [location]); // runtime was complaining we re-render during render so we useEffect
  
  return (
    <Helmet>
      <title>{params["title"]}</title>
      <meta property="og:title" content={params["og:title"]}></meta>
      <meta property="og:description" content={params["og:description"]}></meta>
      <meta name="description" content={params["description"]}></meta>
      <link rel="canonical" href={canonical} />
      <html lang={props.lng}></html>
      {alternates}
    </Helmet>
  );
}

const mapStateToProps = ({ vendors, results, ui, currencies }) => ({
  dir: ui.direction,
  wl: ui.whiteLabel,
  // lng: ui.language,
});

export default connect(mapStateToProps)(Header);